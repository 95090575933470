// $('[data-slider="product"]').slick({
//   dots: false,
//   arrows: true,
//   infinite: true,
//   fade: true,
//   speed: 400,
//   responsive: [
//     {
//       breakpoint: 768,
//       settings: {
//         adaptiveHeight: true
//       }
//     }
//   ]
// });

$('.slider').slick({
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  autoplay: true,
  fade: true,
  autoplaySpeed: 3500
});

// var multislider = $("[data-showcase-text]").length > 0;

// if (multislider) {

//   $('[data-showcase-text]').slick({
//     cssEase: 'linear',
//     dots: false,
//     arrows: false,
//     infinite: true,
//     speed: 400,
//     fade: true,
//     draggable: false,
//     adaptiveHeight: true,
//     autoplay: false,
//     asNavFor: '[data-showcase-img]',
//     responsive: [
//       {
//         breakpoint: 768,
//         settings: {
//           adaptiveHeight: true
//         }
//       }
//     ]
//   });

//   $('[data-showcase-img]').slick({
//     dots: false,
//     infinite: true,
//     speed: 500,
//     asNavFor: '[data-showcase-text]',
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: false,
//     responsive: [
//       {
//         breakpoint: 768,
//         settings: {
//           swipeToSlide: true,
//           centerMode: true,
//           centerPadding: "120px",
//           focusOnSelect: true
//         }
//       }
//     ]
//   });

//   // On before slide change
//   $('[data-showcase-text]').on('beforeChange', function(event, slick, currentSlide, nextSlide){
//     var $nextSlideContents = $(slick.$slides[nextSlide]).find("[data-showcase-animate]"),
//         $currentSlideContents = $(slick.$slides[currentSlide]).find("[data-showcase-animate]"),
//         slideNextAnimation = new TimelineMax();

//     slideNextAnimation
//       .set($nextSlideContents, {opacity: 0, y: 100})
//       .staggerTo($currentSlideContents, 0.4, {y: -50, ease:Power2.easeIn}, 0.03);
//   });      

//   // On After slide change
//   $('[data-showcase-text]').on('afterChange', function(event, slick, currentSlide){
//     var $slideContents = $(slick.$slides[currentSlide]).find("[data-showcase-animate]");
    
//     TweenMax.staggerTo($slideContents, 1.2, {opacity:1, y:0, ease:Expo.easeOut}, 0.09);
//   });

// }

// // if ($("[data-showcase-img]").length > 0 && isBreakPoint(480)) {

// //   // On before slide change
// //   $('[data-showcase-img]').on('beforeChange', function(event, slick, currentSlide, nextSlide){
// //     var $slideContents = $(slick.$slides[currentSlide]);
// //     TweenMax.to($slideContents, 1.2, {scale:1, ease:Expo.easeOut});
// //   });  

// //   // On After slide change
// //   $('[data-showcase-img]').on('afterChange', function(event, slick, currentSlide){
// //     var $slideContents = $(slick.$slides[currentSlide]);
    
// //     TweenMax.to($slideContents, 1.2, {scale:1.5, ease:Expo.easeOut});
// //   });

// // }

// if ($("[data-slider='product']").length > 0) {

//   // $("[data-slider='product']").on('init', function(slick){
//   //   $video = $(".product-slider__slide").find('video').get(0);
//   //   $video.pause();
//   //   $video.currentTime = 0;
//   // });

//   $currentSlideVideo = $(".product-slider__slide.slick-current").find('video').get(0);
//   $currentSlideVideo.autoplay = 1;
//   $currentSlideVideo.load();

//   $("[data-slider='product']").on('beforeChange', function(event, slick, currentSlide, nextSlide){
//     var $nextSlideContents = $(slick.$slides[nextSlide]).find("[data-fade-element], [data-slide-element]"),
//         $currentSlideContents = $(slick.$slides[currentSlide]).find("[data-fade-element], [data-slide-element]"),
//         $nextSlideVideo = $(slick.$slides[nextSlide]).find('video').get(0),
//         slideNextAnimation = new TimelineMax();

//     slideNextAnimation
//       .set($nextSlideContents, {opacity: 0, y: 100})
//       .staggerTo($currentSlideContents, 0.4, {y: -50, ease:Power2.easeIn}, 0.03);

//     // $("[data-slider='product']").slick('slickPause');


//     $nextSlideVideo.pause();
//     $nextSlideVideo.currentTime = 0;
//     // $nextSlideVideo.addEventListener('pause', myHandler, false);
  
//     // function myHandler(e) {
//     //   $("[data-slider='product']").slick('slickPlay');
//     // }
//   });

//   $("[data-slider='product']").on('afterChange', function(event, slick, currentSlide){
//     var $slideContents = $(slick.$slides[currentSlide]).find("[data-fade-element], [data-slide-element]"),
//         $slideImg = $(slick.$slides[currentSlide]).find("[data-fade-element], [data-slide-element]"),
//         $currentSlideVideo = $(slick.$slides[currentSlide]).find('video').get(0);

//     TweenMax.staggerTo($slideContents, 1.2, {opacity:1, y:0, ease:Expo.easeOut}, 0.09);

//     // $("[data-slider='product']").slick('slickPause');
//     //$currentSlideVideo.play();
//     $currentSlideVideo.autoplay = 1;
//     $currentSlideVideo.load();
//     /*console.log($currentSlideVideo,'currslidevideo');
//       if ($currentSlideVideo.readyState >= 2) {
//         console.log('readystatee > 2');
//           $currentSlideVideo.play();
//       } else {
//         console.log('addlistener');
//           $currentSlideVideo.addEventListener('loadeddata', function() {

//             console.log('listener triggered');
//                 $currentSlideVideo.play();


//           });
//       }*/
//     // $currentSlideVideo.addEventListener('ended', myHandler, false);
    
//     // function myHandler(e) {
//     //   $("[data-slider='product']").slick('slickPlay');
//     // }
//   });
// }