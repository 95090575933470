var controller = new ScrollMagic.Controller();

$('[data-fade-in-up]').each(function() {
  
  var $scrollElements = $(this).find("[data-fade-element]"),
      scrollAnimation = new TimelineMax();

  scrollAnimation.staggerFrom($scrollElements,1.2,{opacity:0, y:100, ease:Expo.easeOut}, 0.09);

  var scene = new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: 0.7,
    reverse: false
  })

  .setTween(scrollAnimation)
  // .addIndicators()
  .addTo(controller);
});

$('[data-fade-in-left]').each(function() {
  
  var $scrollElements = $(this).find("[data-fade-element]"),
      scrollAnimation = new TimelineMax();

  scrollAnimation.staggerFrom($scrollElements,1.2,{opacity:0, x:-100, ease:Expo.easeOut}, 0.09, 0.6);

  var scene = new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: 0.7,
    reverse: false
  })

  .setTween(scrollAnimation)
  // .addIndicators()
  .addTo(controller);
});

// $('[data-fade-in-down]').each(function() {
  
//   var $scrollElements = $(this).find("[data-fade-element]"),
//       $scrollBrand = $(this).find(".navbar-brand"),
//       scrollAnimation = new TimelineMax();

//   scrollAnimation
//     .from($scrollBrand,0.7,{opacity:0, ease:Expo.easeOut}, 0.1)
//     .staggerFrom($scrollElements,1,{opacity:0, y:-15, ease:Expo.easeOut}, 0.09, "-=0.3");


//   var scene = new ScrollMagic.Scene({
//     triggerElement: this,
//     triggerHook: 0.7,
//     reverse: false
//   })

//   .setTween(scrollAnimation)
//   // .addIndicators()
//   .addTo(controller);
// });

$('[data-facts-animation-left]').each(function() {
  
  var $dataElement = $(this).find("[data-facts-element]"),
      $dataLine = $(this).find("[data-facts-line]"),
      scrollAnimation = new TimelineMax();

  scrollAnimation
    .from($dataLine,0.7,{width:0, ease:Expo.easeOut, clearProps:"all"})
    .staggerFrom($dataElement,1,{opacity:0, x:-35, ease:Expo.easeOut}, 0.09, "-=0.4");


  var scene = new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: 0.9,
    reverse: false
  })

  .setTween(scrollAnimation)
  // .addIndicators()
  .addTo(controller);
});

$('[data-facts-animation-right]').each(function() {
  
  var $dataElement = $(this).find("[data-facts-element]"),
      $dataLine = $(this).find("[data-facts-line]"),
      scrollAnimation = new TimelineMax();

  scrollAnimation
    .from($dataLine,0.7,{width:0, ease:Expo.easeOut, clearProps:"all"})
    .staggerFrom($dataElement,1,{opacity:0, x:35, ease:Expo.easeOut}, 0.09, "-=0.4");

  var factsScene1 = new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: 0.9,
    reverse: false
  })

  .setTween(scrollAnimation)
  // .addIndicators()
  .addTo(controller);
});

$('[data-values-animation]').each(function() {
  
  var $dataElement = $(this).find("[data-values-element]"),
      $dataLine = $(this).find("[data-values-line]"),
      $dataLabel = $(this).find("[data-values-label]"),
      scrollAnimation = new TimelineMax();

  scrollAnimation
    .from($dataLine,1,{height:0, ease:Expo.easeOut});

  var factsScene1 = new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: 0.5,
    reverse: false
  })

  .setTween(scrollAnimation)
  // .addIndicators()
  .addTo(controller);
});

$('[data-slide-up]').each(function() {

  var scrollAnimation = new TimelineMax();
  var $scrollElements = $(this).find("[data-slide-element]");

  scrollAnimation.staggerFrom($scrollElements,2,{y:60, ease:Expo.easeOut}, 0.03);

  var factsScene2 = new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: 0.8,
    reverse: false
  })

  .setTween(scrollAnimation)
  .addIndicators()
  .addTo(controller);

});


var scene2 = new ScrollMagic.Scene({
  offset: 100,
  triggerHook: 0
})

.setClassToggle('body', 'navbar--scrolled')
// .addIndicators()
.addTo(controller);

var scene3 = new ScrollMagic.Scene({
  offset: 10,
  triggerHook: 0
})

.setClassToggle('body', 'js--transition')
// .addIndicators()
.addTo(controller);



$(".navbar-toggler").click(function() {
  setTimeout(function(){
    $("body").toggleClass("navbar--open");
  }, 100);

});



